import { tns } from "~/node_modules/tiny-slider/src/tiny-slider"

function slideChanged(info) {
  console.log(`Current slide ${info.index} is ${info.slideItems[info.index].dataset.color}`)
  document.body.className = `is-${info.slideItems[info.index].dataset.color}`
}

function initSlider() {
  const slider = tns({
    container: '.js-slider',
    mode: 'gallery',
    items: 1,
    autoplay: true,
    controls: false,
    nav: false,
    loop: true,
    speed: 1000,
    autoplayTimeout: 7000,
    autoplayButtonOutput: false
  })

  slideChanged(slider.getInfo())
  slider.events.on('indexChanged', slideChanged)
}

initSlider()
